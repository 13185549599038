
import React from 'react';
import PropTypes from 'prop-types';

import UserTriggersTable from '../../containers/domain/user-trigger/UserTriggersTable';
import RegularCard from '../../mui-components/Card/RegularCard';
import GridContainer from '../../mui-components/Grid/GridContainer';
import ItemGrid from '../../mui-components/Grid/GridItem';

const UserTriggersListingView = ({ match }) => {
  return (
    <GridContainer>
      <ItemGrid xs={12}>
        <RegularCard
          title="Lista de Triggers de Clientes"
          content={<UserTriggersTable pathId={match.params.id} />}
        />
      </ItemGrid>
    </GridContainer>
  );
};

UserTriggersListingView.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
};

export default UserTriggersListingView;
