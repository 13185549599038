import React from 'react';

const userTriggerTableColumns = [
  {
    name: 'clientName',
    title: 'Nome do Cliente',
    meta: { valueKey: "id", labelKey: "name" },
    width: 250,
  },
  {
    name: 'clientCpf',
    title: 'CPF do Cliente',
    editable: false,
    width: 150,
  },
  {
    name: 'clientEmail',
    title: 'E-mail do Cliente',
    editable: false,
    width: 250,
  },
  {
    name: 'active',
    title: 'Envios Habilitados',
    editable: false,
    width: 180,
    getCellValue: (rowData) => {
      const value = rowData.active ? 'Sim' : 'Não';
      const color = rowData.active ? 'green' : 'red';
      return <span style={{ color }}>{value}</span>;
    },
    hasFilter: false,
  },
  {
    name: 'emailEnabled',
    title: 'via E-mail',
    editable: false,
    width: 133,
    getCellValue: (rowData) => (
      rowData.emailEnabled ? 'Sim' : 'Não'
    ),
    hasFilter: false,
  },
  {
    name: 'wppEnabled',
    title: 'via WPP',
    editable: false,
    width: 133,
    getCellValue: (rowData) => (
      rowData.wppEnabled ? 'Sim' : 'Não'
    ),
    hasFilter: false,
  },
];

export default userTriggerTableColumns;
