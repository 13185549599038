import createHistory from 'history/createBrowserHistory';
import { routerMiddleware } from 'react-router-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import loggerMiddleware from 'redux-logger';
import thunkMiddleware from 'redux-thunk';

import DevTools from './containers/general/debug/DevTools';
import apiMiddleware from './middlewares/apiMiddleware';
import rootReducer from './reducers/rootReducer';
import environment from './utils/environment';

const history = createHistory({ basename: '/' });
const routeMiddleware = routerMiddleware(history);

const loadStore = () => {
  const persistedState = getPersistedState();
  const enhancer = createEnhancer();
  const store = createStore(rootReducer, persistedState, enhancer);

  store.subscribe(() => saveState(store.getState()));

  return store;
};

const getPersistedState = () => {
  const persistedState = JSON.parse(localStorage.getItem('reduxState'));
  return persistedState || undefined;
};

const createEnhancer = () => {
  const middlewares = [apiMiddleware, thunkMiddleware, routeMiddleware];

  let composeEnhancers = compose; // Default compose function

  if (environment.isDebug()) {
    // Se Redux DevTools Extension estiver disponível, usa ela
    if (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
      composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
    } else {
      // Se não, usa a DevTools do projeto
      return compose(
        applyMiddleware(...middlewares),
        DevTools.instrument(),
      );
    }

    middlewares.push(loggerMiddleware);
  }

  return composeEnhancers(applyMiddleware(...middlewares));
};

const saveState = ({ session }) => {
  const savedState = { session };
  const serializedState = JSON.stringify(savedState);
  localStorage.setItem('reduxState', serializedState);
};

const store = loadStore();

export { history, store };
