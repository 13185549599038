const triggerTypeTableColumns = [
  {
    name: 'description',
    title: 'Descrição',
    editable: false,
    width: 500,
  },
];

export default triggerTypeTableColumns;
