
import React from 'react';

import TriggerTypeTable from '../../containers/domain/trigger-type/TriggerTypeTable';
import RegularCard from '../../mui-components/Card/RegularCard';
import GridContainer from '../../mui-components/Grid/GridContainer';
import ItemGrid from '../../mui-components/Grid/GridItem';

const TriggersListingView = () => (
  <GridContainer>
    <ItemGrid xs={12}>
      <RegularCard
        title="Lista de triggers"
        content={<TriggerTypeTable />}
      />
    </ItemGrid>
  </GridContainer>
);

export default TriggersListingView;
