import { schema } from 'normalizr';

export const client = new schema.Entity('clients');
export const clientSpreadsheet = new schema.Entity('clientSpreadsheets');
export const contractType = new schema.Entity('contractTypes');
export const employee = new schema.Entity('employees');
export const internEnterprise = new schema.Entity('internEnterprises');
export const investmentFund = new schema.Entity('investmentFunds');
export const partnerEnterprise = new schema.Entity('partnerEnterprises');
export const profession = new schema.Entity('professions');
export const report = new schema.Entity('reports');
export const spreadsheet = new schema.Entity('spreadsheets');
export const spreadsheetIncome = new schema.Entity('spreadsheetIncomes');
export const task = new schema.Entity('tasks');
export const triggerType = new schema.Entity('triggerTypes');
export const userTrigger = new schema.Entity('userTriggers');
