
import userTriggerActions from '../../../actions/userTriggerActions';
import userTriggerInputFields from './userTriggerInputFields';
import generateEntityEditor from '../../../hocs/generateEntityEditor';

const UserTriggerEditor = generateEntityEditor({
  entitySingularString: 'userTrigger',
  entityPluralString: 'userTriggers',
  entityActions: userTriggerActions,
  returnRoutePath: '/trigger-types-listing',
  fields: userTriggerInputFields,
});

export default UserTriggerEditor;
