import React from 'react';

import generateEntityTable from '../../../hocs/generateEntityTable';
import triggerTypeActions from '../../../actions/triggerTypeActions';
import triggerTypeTableColumns from './TriggerTypeTableColumns';

const TriggerTypesTable = () => {
  const Component = generateEntityTable({
    entityPluralString: 'triggerTypes',
    entityActions: triggerTypeActions,
    columns: triggerTypeTableColumns,
    openOperation: true,
    openRoutePath: '/trigger-types/:id/user-triggers',
  });

  return <Component />;
};

export default TriggerTypesTable;
