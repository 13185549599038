import moment from 'moment';
import React from 'react';

import clientActions from '../../../actions/clientActions';
import contractTypeActions from '../../../actions/contractTypeActions';
import employeeActions from '../../../actions/employeeActions';
import professionActions from '../../../actions/professionActions';
import { getAbility } from '../../../reducers/sessionReducer';
import { store } from '../../../store';
import dateUtils from '../../../utils/dateUtils';
import sortObjectsArrayByProperty from '../../../utils/sortObjectsArrayByProperty';
import ConfTableBaseCell from '../../general/conf-table/ConfTableBaseCell';

const taskTableColumns = [
  {
    name: 'clientId',
    title: 'Cliente',
    type: 'object-select',
    meta: { valueKey: 'id', labelKey: 'name' },
    options: [],
    async: {
      fetch: () => store.dispatch(clientActions.fetchAllSimple()),
      fetchDelay: 500,
      mapSuccessfulResult: response => sortObjectsArrayByProperty(Object.values(response.data.entities.clients), 'name'),
      mapProps: ({ response, ...others }) => ({ ...others, options: response }),
    },
    getCellValue: row => (row.client || {}).name,
    width: 170,
  },
  {
    name: 'advisorId',
    title: 'Assessor',
    notRenderable: getAbility(store.getState()).cannot('update', 'Task'),
    type: 'object-select',
    meta: { valueKey: 'id', labelKey: 'name' },
    options: [],
    async: {
      fetch: () => store.dispatch(employeeActions.fetchAllSimple()),
      fetchDelay: 500,
      mapSuccessfulResult: response => sortObjectsArrayByProperty(Object.values(response.data.entities.employees), 'name'),
      mapProps: ({ response, ...others }) => ({ ...others, options: response }),
    },
    getCellValue: row => (row.advisor || {}).name,
    width: 145,
    visible: false,
  },
  {
    name: 'done',
    title: 'Feito',
    editable: true,
    type: 'checkbox',
    filterInitialValue: { on: false, off: true },
    width: 80,
  },
  {
    name: 'clientXpCode',
    title: 'Código XP do cliente',
    getCellValue: row => (row.client || {}).xpCode,
    visible: false,
    width: 155,
  },
  {
    name: 'clientProfessionId',
    title: 'Profissão do cliente',
    type: 'object-select',
    meta: { valueKey: 'id', labelKey: 'name' },
    options: [],
    async: {
      fetch: () => store.dispatch(professionActions.fetchAll()),
      fetchDelay: 500,
      mapSuccessfulResult: response => sortObjectsArrayByProperty(Object.values(response.data.entities.professions), 'name'),
      mapProps: ({ response, ...others }) => ({ ...others, options: response }),
    },
    getCellValue: row => ((row.client || {}).profession || {}).name,
    width: 149,
    visible: false,
  },
  {
    name: 'contactDate',
    title: 'Data de contato',
    editable: true,
    type: 'date',
    filterInitialValue: { maxValue: moment().add(30, 'days').format('DD/MM/YYYY') },
    width: 128,
  },
  {
    name: 'description',
    title: 'Descrição',
    editable: true,
    width: 600,
  },
  {
    name: 'clientRelationshipType',
    title: 'Status do cliente',
    type: 'select',
    options: ['Ex-cliente', 'Potencial cliente', 'Cliente', 'Sem interesse'],
    getCellValue: row => (row.client || {}).relationshipType,
    width: 172,
  },
  {
    name: 'clientEmail',
    title: 'Email do cliente',
    getCellValue: row => (row.client || {}).email,
    width: 172,
  },
  {
    name: 'clientPhone',
    title: 'Telefone do cliente',
    getCellValue: row => (row.client || {}).telephone,
    width: 172,
  },
  {
    name: 'primaryStage',
    title: 'Etapa do funil',
    type: 'select',
    options: [
      '1 - Primeiro contato',
      '2 - Habilitação',
      '3 - Diagnóstico do Plano de ação',
      '4 - Implementação do Plano de Ação',
      '5 - Acompanhamento',
    ],
    editable: true,
    width: 150,
  },
  {
    name: 'secondaryStage',
    title: 'Subetapa do funil',
    type: 'select',
    options: [
      '1.1 - Agendar a reunião de apresentação dos Planos Confiança',
      '1.2 - Solicitar indicação, agradecer e registrar no CRM',
      '1.3 - Outros',
      '2.1 - Apresentar os Planos Confiança',
      '2.1.1 - Solicitar pagamento do Plano Confiança escolhido',
      '2.1.1.1 - Coletar as informações do cliente',
      '2.1.1.2 - Agendar a apresentação do Plano de Ação',
      '2.1.2 - Solicitar indicação, agradecer e registrar no CRM',
      '2.2 - Outros',
      '3.1 - Elaborar o Plano de Ação',
      '3.2 - Elaborar estudo de investimentos',
      '3.3 - Elaborar estudo de seguros',
      '3.4 - Elaborar estudo de previdência',
      '3.5 - Elaborar estudo diversos',
      '3.6 - Apresentar os estudos e solicitar indicação',
      '3.7 - Outros',
      '4.1 - Abrir conta na corretora ou transferência de assessor',
      '4.1.1 - Implementar estudo de investimentos',
      '4.1.2 - Contratar / portabilidade da previdência privada',
      '4.1.2.1 - Monitorar a implementação da previdência privada',
      '4.2 - Preencher e enviar a proposta do seguro pessoal',
      '4.2.1 - Monitorar a implementação do seguro pessoal',
      '4.3 - Outros',
      '5.1 - Contato ativo mensal',
      '5.2 - Revisão da Carteira/Plano e solicitar indicação',
      '5.3 - Reunião de revisão do plano ( presencial ) e solicitar indicação',
      '5.4 - Outros',
    ],
    editable: true,
    width: 150,
  },
  {
    name: 'startTime',
    title: 'Hora de início',
    width: 150,
    visible: false,
  },
  {
    name: 'endTime',
    title: 'Hora fim',
    width: 150,
    visible: false,
  },
  {
    name: 'location',
    title: 'Local',
    type: 'select',
    options: ['Sala 1', 'Sala 2', 'Externo'],
    width: 150,
    visible: false,
  },
  {
    name: 'contactMethod',
    title: 'Forma de contato',
    editable: true,
    width: 136,
    visible: false,
  },
  {
    name: 'activeContractType',
    title: 'Contrato ativo',
    type: 'object-select',
    meta: { valueKey: 'id', labelKey: 'name' },
    options: [],
    async: {
      fetch: () => store.dispatch(contractTypeActions.fetchAll()),
      fetchDelay: 500,
      mapSuccessfulResult: response => sortObjectsArrayByProperty(Object.values(response.data.entities.contractTypes), 'name'),
      mapProps: ({ response, ...others }) => ({ ...others, options: response }),
    },
    getCellValue: row => (((row.client || {}).activeContract || {}).contractType || {}).name,
    width: 121,
    visible: false,
  },
  {
    name: 'activeContractStartDate',
    title: 'Início do contrato',
    type: 'date',
    getCellValue: row => ((row.client || {}).activeContract || {}).startDate,
    width: 135,
    visible: false,
  },
  {
    name: 'clientInvestmentProfile',
    title: 'Perfil do cliente',
    type: 'select',
    options: ['Conservador', 'Conservador moderado', 'Moderado', 'Moderado agressivo', 'Agressivo'],
    getCellValue: row => (row.client || {}).investmentProfile,
    width: 123,
    visible: false,
  },
  {
    name: 'clientSegmentation',
    title: 'Segmentação do cliente',
    getCellValue: row => (row.client || {}).segmentation,
    width: 171,
    visible: false,
  },
  {
    name: 'clientReferral',
    title: 'Indicação do cliente',
    getCellValue: row => (row.client || {}).referral,
    width: 149,
    visible: false,
  },
];

const taskTableLeftFixedColumns = ['operations', 'clientId'];

const taskTableDataSortingByColumns = [{ columnName: 'contactDate', direction: 'asc' }];

const taskTableSummaries = [
  { columnName: 'clientId', type: 'count' },
];

const TaskTableCellComponent = (props) => {
  const { done } = props.row;
  let { contactDate } = props.row;
  const style = { ...props.style };

  contactDate = dateUtils.convertStringToDate(contactDate);
  const today = new Date();
  const timeDiff = today.getTime() - contactDate.getTime();
  const diffDays = Math.floor(timeDiff / (1000 * 3600 * 24));

  if (done) {
    style.backgroundColor = '#ccebff';
  } else if (diffDays > 0) {
    style.backgroundColor = '#ffcccc';
  } else if (diffDays === 0) {
    style.backgroundColor = '#adebad';
  } else if (diffDays > -8) {
    style.backgroundColor = '#d6f5d6';
  }

  return <ConfTableBaseCell {...props} style={style} />;
};

export {
  TaskTableCellComponent, taskTableDataSortingByColumns, taskTableLeftFixedColumns, taskTableSummaries
};
export default taskTableColumns;
