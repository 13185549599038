import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DashboardRoundedIcon from '@material-ui/icons/DashboardRounded';
import Image from '@material-ui/icons/Image';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import ClientCreationView from '../../views/clients/ClientCreationView';
import ClientEditionView from '../../views/clients/ClientEditionView';
import ClientsListingView from '../../views/clients/ClientsListingView';
import ClientsMergingView from '../../views/clients/ClientsMergingView';
import ClientsNewTaskView from '../../views/clients/ClientsNewTaskView';
import ClientSpreadsheetCreationView from '../../views/clients/ClientSpreadsheetCreationView';
import ClientSpreadsheetsListingView from '../../views/clients/ClientSpreadsheetsListingView';
import ClientsReportView from '../../views/clients/ClientsReportView';
import MultipleClientEditionView from '../../views/clients/MultipleClientEditionView';
import MultipleClientMailView from '../../views/clients/MultipleClientMailView';
import MyClientCreationView from '../../views/clients/MyClientCreationView';
import MyClientEditionView from '../../views/clients/MyClientEditionView';
import MyClientsListingView from '../../views/clients/MyClientsListingView';
import MyProspectCreationView from '../../views/clients/MyProspectCreationView';
import ProspectCreationView from '../../views/clients/ProspectCreationView';
import ContractTypeCreationView from '../../views/contract-types/ContractTypeCreationView';
import ContractTypeEditionView from '../../views/contract-types/ContractTypeEditionView';
import ContractTypesListingView from '../../views/contract-types/ContractTypesListingView';
import Dashboard from '../../views/dashboard/Dashboard/index';
import MainDashboardView from '../../views/dashboard/Dashboard/MainDashboardView';
import EmployeeCreationView from '../../views/employees/EmployeeCreationView';
import EmployeeEditionView from '../../views/employees/EmployeeEditionView';
import EmployeesListingView from '../../views/employees/EmployeesListingView';
import MyProfileView from '../../views/employees/MyProfileView';
import InternEnterpriseCreationView from '../../views/intern-enterprises/InternEnterpriseCreationView';
import InternEnterpriseEditionView from '../../views/intern-enterprises/InternEnterpriseEditionView';
import InternEnterprisesListingView from '../../views/intern-enterprises/InternEnterprisesListingView';
import PartnerEnterpriseCreationView from '../../views/partner-enterprises/PartnerEnterpriseCreationView';
import PartnerEnterpriseEditionView from '../../views/partner-enterprises/PartnerEnterpriseEditionView';
import PartnerEnterprisesListingView from '../../views/partner-enterprises/PartnerEnterprisesListingView';
import MyTaskEditionView from '../../views/tasks/MyTaskEditionView';
import MyTasksListingView from '../../views/tasks/MyTasksListingView';
import TaskEditionView from '../../views/tasks/TaskEditionView';
import TasksListingView from '../../views/tasks/TasksListingView';
import TasksReportView from '../../views/tasks/TasksReportView';
import pagesRoutes from './pagesRoutes';
// import InvestmentFundsListingView from '../../views/investment-funds/InvestmentFundsListingView';
// import InvestmentFundCreationView from '../../views/investment-funds/InvestmentFundCreationView';
// import InvestmentFundEditionView from '../../views/investment-funds/InvestmentFundEditionView';
import MySpreadsheetIncomesFilterView from '../../views/incomes/MySpreadsheetIncomesFilterView';
import MySpreadsheetIncomesListingView from '../../views/incomes/MySpreadsheetIncomesListingView';
import NotIdentifiedSpreadsheetIncomesListingView from '../../views/incomes/NotIdentifiedSpreadsheetIncomesListingView';
import SpreadsheetCreationView from '../../views/incomes/SpreadsheetCreationView';
import SpreadsheetIncomesChartsView from '../../views/incomes/SpreadsheetIncomesChartsView';
import SpreadsheetIncomesFilterView from '../../views/incomes/SpreadsheetIncomesFilterView';
import SpreadsheetIncomesListingView from '../../views/incomes/SpreadsheetIncomesListingView';
import SpreadsheetsListingView from '../../views/incomes/SpreadsheetsListingView';
import TriggersListingView from '../../views/trigger-types/TriggerTypeListingView';
import UserTriggersListingView from '../../views/user-triggers/UserTriggersListingView';
import UserTriggerEditionView from '../../views/user-triggers/UserTriggerEditionView';

const dashRoutes = [
  {
    hidden: true,
    path: '/dashboard',
    name: 'Dashboard',
    permission: { action: 'read', subject: 'Dashboard' },
    icon: DashboardIcon,
    component: Dashboard,
  },
  {
    path: '/main-dashboard',
    name: 'Dashboard',
    icon: DashboardRoundedIcon,
    permission: { action: 'read', subject: 'MainDashboard' },
    component: MainDashboardView,
  },
  {
    collapse: true,
    path: '-employees',
    name: 'Assessores',
    state: 'openEmployees',
    icon: AssignmentIndIcon,
    views: [
      {
        path: '/employees-listing',
        name: 'Lista de assessores',
        permission: { action: 'read', subject: 'Employee' },
        component: EmployeesListingView,
      },
      {
        path: '/employee-creation',
        name: 'Cadastrar assessor',
        permission: { action: 'create', subject: 'Employee' },
        component: EmployeeCreationView,
      },
      {
        hidden: true,
        path: '/employee-edition/:id',
        name: 'Atualização de assessor',
        permission: { action: 'update', subject: 'Employee' },
        component: EmployeeEditionView,
      },
      {
        hidden: true,
        path: '/my-profile-edition',
        name: 'Meus dados',
        permission: { action: 'update', subject: 'Myself' },
        component: MyProfileView,
      },
    ],
  },
  {
    collapse: true,
    path: '-clients',
    name: 'Clientes',
    state: 'openClients',
    icon: PeopleOutlineIcon,
    views: [
      {
        path: '/clients-report',
        name: 'Relatórios',
        permission: { action: 'read', subject: 'DashboardClients' },
        component: ClientsReportView,
      },
      {
        path: '/clients-listing',
        name: 'Lista de clientes',
        permission: { action: 'read', subject: 'Client' },
        component: ClientsListingView,
      },
      {
        path: '/my-clients-listing',
        name: 'Meus clientes',
        permission: { action: 'read', subject: 'MyClient' },
        component: MyClientsListingView,
      },
      {
        path: '/client-creation',
        name: 'Cadastrar cliente',
        permission: { action: 'create', subject: 'Client' },
        component: ClientCreationView,
      },
      {
        path: '/my-client-creation',
        name: 'Cadastrar cliente',
        permission: { action: 'create', subject: 'MyClient' },
        component: MyClientCreationView,
      },
      {
        path: '/prospect-creation',
        name: 'Cadastrar prospect',
        permission: { action: 'create', subject: 'Client' },
        component: ProspectCreationView,
      },
      {
        path: '/my-prospect-creation',
        name: 'Cadastrar prospect',
        permission: { action: 'create', subject: 'MyClient' },
        component: MyProspectCreationView,
      },
      {
        hidden: true,
        path: '/client-edition/:id',
        name: 'Atualização de cliente',
        permission: { action: 'update', subject: 'Client' },
        component: ClientEditionView,
      },
      {
        hidden: true,
        path: '/my-client-edition/:id',
        name: 'Atualização de cliente',
        permission: { action: 'update', subject: 'MyClient' },
        component: MyClientEditionView,
      },
      {
        path: '/client-spreadsheet-listing/:id',
        name: 'Lista de planilhas',
        permission: { action: 'read', subject: 'ClientSpreadsheet' },
        component: ClientSpreadsheetsListingView,
      },
      {
        path: '/client-spreadsheet-creation/:id',
        name: 'Importar planilha',
        permission: { action: 'create', subject: 'ClientSpreadsheet' },
        component: ClientSpreadsheetCreationView,
      },
      {
        hidden: true,
        path: '/clients-merging',
        name: 'Merging de clientes',
        permission: { action: 'update', subject: 'Client' },
        component: ClientsMergingView,
      },
      {
        hidden: true,
        path: '/clients-task-creation',
        name: 'Tarefas para clientes',
        permission: { action: 'create', subject: 'MultipleTask' },
        component: ClientsNewTaskView,
      },
      {
        hidden: true,
        path: '/multiple-client-edition',
        name: 'Atualização de clientes',
        permission: { action: 'update', subject: 'MultipleClient' },
        component: MultipleClientEditionView,
      },
      {
        hidden: true,
        path: '/send-client-emails',
        name: 'Envio de email em massa',
        permission: { action: 'create', subject: 'MultipleClientEmail' },
        component: MultipleClientMailView,
      },
    ],
  },
  {
    collapse: true,
    path: '-tasks',
    name: 'Tarefas',
    state: 'openTasks',
    icon: CheckCircleOutlineIcon,
    views: [
      {
        path: '/acompanhamento-de-tarefas',
        name: 'Acompanhamento de tarefas',
        permission: { action: 'read', subject: 'MyTask' },
        component: TasksReportView,
      },
      {
        path: '/tasks-listing',
        name: 'Lista de tarefas',
        permission: { action: 'read', subject: 'Task' },
        component: TasksListingView,
      },
      {
        path: '/my-tasks-listing',
        name: 'Minhas tarefas',
        permission: { action: 'read', subject: 'MyTask' },
        component: MyTasksListingView,
      },
      {
        hidden: true,
        path: '/task-edition/:id',
        name: 'Atualização de tarefa',
        permission: { action: 'update', subject: 'Task' },
        component: TaskEditionView,
      },
      {
        hidden: true,
        path: '/my-task-edition/:id',
        name: 'Atualização de tarefa',
        permission: { action: 'update', subject: 'MyTask' },
        component: MyTaskEditionView,
      },
    ],
  },
  {
    collapse: true,
    path: '-contract-types',
    name: 'Contratos',
    state: 'openContractTypes',
    icon: ListAltIcon,
    views: [
      {
        path: '/contract-types-listing',
        name: 'Lista de contratos',
        permission: { action: 'read', subject: 'ContractType' },
        component: ContractTypesListingView,
      },
      {
        path: '/contract-type-creation',
        name: 'Cadastrar contrato',
        permission: { action: 'create', subject: 'ContractType' },
        component: ContractTypeCreationView,
      },
      {
        hidden: true,
        path: '/contract-type-edition/:id',
        name: 'Atualização de contrato',
        permission: { action: 'update', subject: 'ContractType' },
        component: ContractTypeEditionView,
      },
    ],
  },
  {
    collapse: true,
    path: '-intern-enterprises',
    name: 'Empresas',
    state: 'openInternEnterprises',
    icon: LocationCityIcon,
    views: [
      {
        path: '/intern-enterprises-listing',
        name: 'Lista de empresas',
        permission: { action: 'read', subject: 'InternEnterprise' },
        component: InternEnterprisesListingView,
      },
      {
        path: '/intern-enterprise-creation',
        name: 'Cadastrar empresa',
        permission: { action: 'create', subject: 'InternEnterprise' },
        component: InternEnterpriseCreationView,
      },
      {
        hidden: true,
        path: '/intern-enterprise-edition/:id',
        name: 'Atualização de empresa',
        permission: { action: 'update', subject: 'InternEnterprise' },
        component: InternEnterpriseEditionView,
      },
    ],
  },
  {
    collapse: true,
    path: '-partner-enterprises',
    name: 'Fornecedores',
    state: 'openPartnerEnterprises',
    icon: LocalShippingIcon,
    views: [
      {
        path: '/partner-enterprises-listing',
        name: 'Lista de fornecedores',
        permission: { action: 'read', subject: 'PartnerEnterprise' },
        component: PartnerEnterprisesListingView,
      },
      {
        path: '/partner-enterprise-creation',
        name: 'Cadastrar fornecedor',
        permission: { action: 'create', subject: 'PartnerEnterprise' },
        component: PartnerEnterpriseCreationView,
      },
      {
        hidden: true,
        path: '/partner-enterprise-edition/:id',
        name: 'Atualização de fornecedor',
        permission: { action: 'update', subject: 'PartnerEnterprise' },
        component: PartnerEnterpriseEditionView,
      },
    ],
  },
  /* {
    collapse: true,
    path: '-investment-funds',
    name: 'Fundos de investimento',
    state: 'openInvestmentFunds',
    icon: StarIcon,
    views: [
      {
        path: '/investment-funds-listing',
        name: 'Lista de fundos',
        permission: { action: 'read', subject: 'InvestmentFund' },
        mini: 'LF',
        component: InvestmentFundsListingView,
      },
      {
        path: '/investment-fund-creation',
        name: 'Cadastrar fundo',
        permission: { action: 'create', subject: 'InvestmentFund' },
        mini: 'CF',
        component: InvestmentFundCreationView,
      },
      {
        hidden: true,
        path: '/investment-fund-edition/:id',
        name: 'Atualização de fundo',
        permission: { action: 'update', subject: 'InvestmentFund' },
        mini: 'AF',
        component: InvestmentFundEditionView,
      },
    ],
  }, */
  {
    collapse: true,
    path: '-incomes',
    name: 'Receitas',
    state: 'openIncomes',
    icon: AttachMoneyIcon,
    views: [
      {
        path: '/spreadsheet-incomes-report',
        name: 'Relatórios',
        icon: TrendingUpIcon,
        permission: { action: 'read', subject: 'DashboardSpreadsheetIncome' },
        component: SpreadsheetIncomesChartsView,
      },
      {
        path: '/spreadsheet-incomes-filtering',
        name: 'Lista de receitas',
        permission: { action: 'read', subject: 'SpreadsheetIncome' },
        component: SpreadsheetIncomesFilterView,
      },
      {
        hidden: true,
        path: '/spreadsheet-incomes-listing',
        name: 'Lista de receitas',
        permission: { action: 'read', subject: 'SpreadsheetIncome' },
        component: SpreadsheetIncomesListingView,
      },
      {
        path: '/my-spreadsheet-incomes-filtering',
        name: 'Minhas receitas',
        permission: { action: 'read', subject: 'MySpreadsheetIncome' },
        component: MySpreadsheetIncomesFilterView,
      },
      {
        hidden: true,
        path: '/my-spreadsheet-incomes-listing',
        name: 'Minhas receitas',
        permission: { action: 'read', subject: 'MySpreadsheetIncome' },
        component: MySpreadsheetIncomesListingView,
      },
      {
        path: '/not-identified-spreadsheet-incomes-listing',
        name: 'Receitas não-identificadas',
        permission: { action: 'read', subject: 'SpreadsheetIncome' },
        component: NotIdentifiedSpreadsheetIncomesListingView,
      },
      {
        path: '/spreadsheets-listing',
        name: 'Lista de planilhas',
        permission: { action: 'read', subject: 'Spreadsheet' },
        component: SpreadsheetsListingView,
      },
      {
        path: '/spreadsheet-creation',
        name: 'Importar planilha',
        permission: { action: 'create', subject: 'Spreadsheet' },
        component: SpreadsheetCreationView,
      },
    ],
  },
  {
    collapse: true,
    path: '-trigger-types',
    name: 'Notificações IA',
    state: 'openTriggerTypes',
    icon: PlayArrowIcon,
    views: [
      {
        path: '/trigger-types-listing',
        name: 'Lista de Triggers',
        permission: { action: 'read', subject: 'TriggerType' },
        component: TriggersListingView,
      },
      {
        hidden: true,
        path: '/trigger-types/:id/user-triggers',
        name: 'Lista de Triggers de Clientes',
        permission: { action: 'read', subject: 'UserTrigger' },
        component: UserTriggersListingView,
      },
    ],
  },
  {
    collapse: true,
    path: '-user-triggers',
    name: 'Triggers de Clientes',
    state: 'openUserTriggers',
    icon: PlayArrowIcon,
    views: [
      {
        hidden: true,
        path: '/user-trigger-edition/:id',
        name: 'Edição de Trigger de Cliente',
        permission: { action: 'update', subject: 'UserTrigger' },
        component: UserTriggerEditionView,
      },
    ],
  },
  {
    hidden: true,
    collapse: true,
    path: '-page',
    name: 'Pages',
    state: 'openPages',
    icon: Image,
    views: pagesRoutes,
  },
  {
    redirect: true, path: '/', pathTo: '/dashboard', name: 'Dashboard',
  },
];

export default dashRoutes;
