import React from 'react';

import userTriggerActions from '../../../actions/userTriggerActions';
import userTriggerTableColumns from './userTriggerTableColumns';
import generateEntityTable from '../../../hocs/generateEntityTable';

const UserTriggersTable = ({ pathId }) => {
  const Component = generateEntityTable({
    entityPluralString: 'userTriggers',
    entityActions: userTriggerActions,
    editorRoutePath: '/user-trigger-edition',
    columns: userTriggerTableColumns,
    pathId: pathId,
    editOperation: true
  });

  return <Component />;
};

export default UserTriggersTable;
